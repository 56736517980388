import NotificationsPage from "views/Pages/Notifications.js";

import { Apartment, Dashboard, Language, Notifications, Person,  } from "@mui/icons-material";

var dashRoutes = [
  {
    path: "/notifications",
    name: "Notifications",
    icon: Notifications,
    component: NotificationsPage,
    layout: "/admin",
  },
  {
    href:"http://projects",
    name: "Projects",
    icon: Apartment,
  },
  {
    href:"http://contacts",
    name: "Contacts",
    icon: Person,
  },
  {
    href:"http://mislive",
    name: "MIS",
    icon: Dashboard,
  },
  {
    href:"http://ahrintranet",
    name: "Intranet",
    icon: Language,
  }
  // {
  //   path: "/preferences",
  //   name: "Preferences",
  //   icon: Settings,
  //   component: PreferencesPage,
  //   layout: "/admin",
  // },
  // {
  //   path: "/admin",
  //   name: "Admin",
  //   icon: Security,
  //   component: AdminPage,
  //   layout: "/admin",
  // },
  
];
export default dashRoutes;
