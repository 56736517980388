import { Skeleton } from "@mui/material"
import React, {  useEffect, useState } from "react"
import { getTasksSummary } from "services/taskService"
import GridContainer from "../Grid/GridContainer"
import GridItem from "../Grid/GridItem"
import TaskTypeCard from "./TaskTypeCard"
import useFilterStore from "useFilterStore"
import { logError } from "services/loggingService"

export default function TaskTypes() {
    const taskListStatusFilter = useFilterStore(state => state.taskListStatusFilter);
    const officeListFilter = useFilterStore(state => state.officeListFilter);

    const [taskSummary, setTaskSummary] = useState([]);
    const [isTasksSummaryLoading, setIsTasksSummaryLoading] = useState(false);
    const searchText = useFilterStore(state => state.searchText);

    useEffect(()=>{
        function getData(){
            return getTasksSummary(taskListStatusFilter,officeListFilter, searchText)
            .then(setTaskSummary)
            .catch(logError);
        }
        setIsTasksSummaryLoading(true);
        getData().finally(() => setIsTasksSummaryLoading(false))
    },[taskListStatusFilter,officeListFilter, searchText])

    return (<>{
        isTasksSummaryLoading && taskSummary && taskSummary.length === 0 &&
        <GridContainer>
            {
                [1, 2, 3, 4, 5, 6, 7, 8].map(func => {
                    return <GridItem key={func.toString()}>
                        <Skeleton variant="rectangular" width={65} height={62} style={{ borderRadius: 3, marginLeft: 15, position: "relative", top: 45 }} />
                        <Skeleton variant="rectangular" width={200} height={115} style={{ borderRadius: 6 }} />
                    </GridItem>
                })
            }
        </GridContainer>
    }
        {taskSummary && taskSummary.length > 0 ?
            <GridContainer>
                {
                    taskSummary.map(summaryItem => {
                        return !!(summaryItem && summaryItem.id) ?
                            <GridItem key={summaryItem.id.valueOf()}>
                                <TaskTypeCard taskSummary={summaryItem}></TaskTypeCard>
                            </GridItem>
                            : null
                    })
                }
            </GridContainer>
            : null
        }</>)
}