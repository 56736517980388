import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Business, FilterAlt, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight, Search } from "@mui/icons-material/index";
import { Button, Checkbox, CircularProgress, Divider, Drawer,IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, TextField } from "@mui/material/index";
import { List } from "@mui/material/index";
import SidebarFilter from "./SidebarFilter";
import { TaskStatus } from "enums";
import useFilterStore from "useFilterStore";
import { useQuery } from "react-query";
import { getOfficeFilters } from "services/filtersService";
import { useShallow } from "zustand/react/shallow";

/**
 * 
 * @param {Object} param0 
 * @param {Function} param0.filtersApplied
 * @param {Function} param0.resetFilters 
 * @returns 
 */
export default function TaskFilters() {  
  //#region State
  const filterToggle = useFilterStore(useShallow(state => state.filterToggle));
  const setFilterToggle = useFilterStore(state => state.setFilterToggle);
  const [noFiltersApplied, setNoFiltersApplied] = useState(false);  
  const [statusIds, setStatusIds] = useState([TaskStatus.NEW, TaskStatus.UPDATED])
  const [officeIds, setOfficeIds] = useState([]);
  const [filterSearchText, setFilterSearchText] = useState("");
  const setFilters = useFilterStore(state => state.setFilters);
  //#endregion

  const {data: officeFilters, isLoading: isOfficeFiltersLoading} = useQuery(["OfficeFilters"], () => getOfficeFilters(), {  
    staleTime: 10 * (60 * 1000) /* 10 minutes */ ,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });

  const styles = {
    iconStyle: {
      left: "-3px",
      position: "relative"
    },
    iconButtonStyle: {
      position: "fixed",
      background: "white",
      transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
      transform: filterToggle ? "translateX(-349px)" : "translateX(0px)",
      right: 0,
      top: "71px",
      width: "45px",
      borderRadius: "50% 0 0 50%",
      ":hover": {
        background: "rgb(230,230,235)"
      },
      zIndex: 1201
    },
    listStyle: { minWidth: 349, width: 349, maxWidth: 349, overflow: 'auto', position: 'relative', '& ul': { padding: 0 } },
    listItemStyle: {
      paddingTop: 0, 
      paddingBottom: 0, 
      paddingLeft: 0, paddingRight: 0, flex: 0.25, background: "white" 
    },
    drawerStyling: {
      "div.MuiPaper-root": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
      }
    }
  }

  useEffect(() => {
    setNoFiltersApplied(statusIds.length === 0);
  },[statusIds])

  //#region Event Handlers
  function resetFilters(){
    setOfficeIds([])
    setFilterSearchText("")
    setFilters([TaskStatus.NEW,TaskStatus.UPDATED], [], "")
  }

  function handleToggle(event, taskStatusId) {
    let newArr = []
    if(statusIds.includes(taskStatusId)){
      newArr = [...statusIds.filter((item) => item !== taskStatusId)]
    }
    else{
      newArr = [...statusIds, taskStatusId]
    }
    setStatusIds(newArr)
  }

  /**
   * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} ev
   * @param {number} officeId
   */
  function handleOfficeSelection(ev, officeId){
    if(officeIds.includes(officeId)){
      setOfficeIds([...officeIds.filter((item) => item !== officeId)])
    }
    else{
      setOfficeIds([...officeIds, officeId])
    }
  }

  function applyFilters() {
    console.log("Applying filters")
    setFilters(statusIds, officeIds, filterSearchText)
  }
  //#endregion

  //#region Components
  function BusinessIcon(){
    if(isOfficeFiltersLoading){
      return <CircularProgress color="inherit" style={{ height: "25px", width: "25px" }} />
    }
    return <Business />
  }

  function SearchTextFilter(){
    const searchText = useRef("");

    return(
      <>
        <ListItem>
          <ListItemIcon>
            <Search/>
          </ListItemIcon>
          <TextField 
              onChange={(event) => { 
                // setFilterSearchText(event.target.value) 
                searchText.current = event.target.value
              }} 
              onBlur={()=> { setFilterSearchText(searchText.current) }}
              style={{ marginBottom: "0.5rem" }}
              label="Description" 
              variant="standard" 
              size="small"
              defaultValue={filterSearchText}
              fullWidth />   
        </ListItem>
        <Divider/>
      </>
    )
  }

  function FilterButtons(){
    return (
      <div style={{
        display: "flex",
        flexDirection: "column"
      }}>
        <Button disabled={noFiltersApplied} style={{ margin: "0.5rem", borderRadius: 0 }} variant="contained" onMouseUp={() => { applyFilters()}}>Apply</Button>
        <Button style={{ margin: "0.5rem", borderRadius: 0 }} variant="outlined" onClick={() => { resetFilters() }}>Reset</Button>
      </div>
    )
  }
  //#endregion

  return (
    <>
      <IconButton color="secondary" title={!filterToggle ? "Expand filter" : "Collapse filter"} aria-label="Task filter" component="span" onClick={() => { setFilterToggle(!filterToggle) }} sx={styles.iconButtonStyle}>
        {
          filterToggle ?
            <KeyboardDoubleArrowRight sx={styles.iconStyle} /> :
            <KeyboardDoubleArrowLeft sx={styles.iconStyle} />
        }
      </IconButton>
      <Drawer sx={styles.drawerStyling} anchor={"right"} open={filterToggle} onClose={() => { setFilterToggle(false) }}>
        <List sx={styles.listStyle} dense disablePadding subheader={<li />}>
          <ListSubheader>Filters</ListSubheader>
          <SidebarFilter icon={<FilterAlt />} primary="Statuses" open={true}>
            <List sx={{ width: "100%" }}>
                  <ListItem style={styles.listItemStyle} dense>
                    <ListItemButton onClick={(event) => handleToggle(event, TaskStatus.NEW)} dense disableGutters sx={{ paddingTop: 0, paddingBottom: 0, paddingLeft: "2rem" }}>
                      <ListItemText>Unread</ListItemText>
                      <Checkbox checked={statusIds.includes(TaskStatus.NEW)} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem style={styles.listItemStyle} dense>
                    <ListItemButton onClick={(event) => handleToggle(event, TaskStatus.UPDATED)} dense disableGutters sx={{ paddingTop: 0, paddingBottom: 0, paddingLeft: "2rem" }}>
                      <ListItemText>Read</ListItemText>
                      <Checkbox checked={statusIds.includes(TaskStatus.UPDATED)} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem style={styles.listItemStyle} dense>
                    <ListItemButton onClick={(event) => handleToggle(event, TaskStatus.DISMISSED)} dense disableGutters sx={{ paddingTop: 0, paddingBottom: 0, paddingLeft: "2rem" }}>
                      <ListItemText>Dismissed</ListItemText>
                      <Checkbox checked={statusIds.includes(TaskStatus.DISMISSED)} />
                    </ListItemButton>
                  </ListItem>
            </List>
          </SidebarFilter>
          <SidebarFilter icon={<BusinessIcon/>} primary="Offices">
            <List sx={{ maxHeight: "20rem", overflowY: "auto" }}>
              {
                officeFilters && officeFilters.map((office) => {
                  return <ListItem style={styles.listItemStyle} dense key={office.officeId}>
                    <ListItemButton onClick={(event) => handleOfficeSelection(event, office.officeId)} dense disableGutters sx={{ paddingTop: 0, paddingBottom: 0, paddingLeft: "2rem" }}>
                      <ListItemText>{office.officeName}</ListItemText>
                      <Checkbox checked={officeIds.includes(office.officeId)} />
                    </ListItemButton>
                  </ListItem>
                })
              }            
            </List>
          </SidebarFilter>
          <SearchTextFilter/>
        </List>
        <FilterButtons />
      </Drawer>
    </>
  )
}