import React, { useState } from "react"
import { Collapse, Divider, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

export default function SidebarFilter(props){
    const [open, setOpen] = useState(props.open || false);

    function handleClick(){
        if(!props.inline && !props.lockOpen){
            setOpen(!open)
        }
        else if(props.handleClick){
            props.handleClick()
        }
    }

    const paddingHSize = 8;
    const paddingVSize = 8;
    const ListItemButtonStyling ={
        paddingLeft: paddingHSize * 2,
        paddingRight: paddingHSize,
        paddingTop: paddingVSize,
        paddingBottom: paddingVSize,
        cursor: "pointer"
    }

    const ListItemStyling = { paddingTop: 0, paddingBottom: 0, paddingLeft: 0, paddingRight: 0 };
    Object.assign(ListItemStyling, props.style || {});    

    return (<>        
        <ListItem style={ListItemStyling}>
            <ListItemButton onClick={handleClick} style={ListItemButtonStyling}>
            { props.icon &&
                <ListItemIcon>
                    {props.icon}
                </ListItemIcon>
            }
            <ListItemText primary={props.primary} />
            {   !props.inline && !props.lockOpen && 
                (open ? 
                <IconButton>
                    <ExpandLess />
                </IconButton> :
                <IconButton>
                    <ExpandMore />
                </IconButton>)
            }
            {
                props.inline &&
                props.children
            }
            </ListItemButton>
        </ListItem>
        <Divider/>
        { props.children &&
            <Collapse in={props.lockOpen ? true : open} sx={{ bgcolor: 'action.hover' }} className='innerFlex'>
                {props.children}
            </Collapse>
        }
        </>
    )
}