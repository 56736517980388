import React, { useEffect, useState } from "react";

// core components
import { AuthenticatedTemplate } from "@azure/msal-react";
import { Badge, ClickAwayListener, IconButton, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import { Notifications } from "@mui/icons-material";
import { SignalRTaskContext } from "App";
import useFilterStore from "useFilterStore";

export default function HeaderLinks(props) {
  const [openNotification, setOpenNotification] = React.useState(null);
  /**
 * @type {[Task[],React.Dispatch<any>]}
 */
  const [notifications, setNotifications] = useState([])
  /**
   * @type {[number,React.Dispatch<any>]}
   */
  const [notificationsAmt, setNotificationsAmt] = useState(0);
  const setNewTask = useFilterStore(state => state.setNewTask)

  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };

  const handleCloseNotification = (event, task) => {
    if(task){
      // @ts-ignore
      setNewTask(task);
      setNotificationsAmt(0);
      notifications.splice(notifications.indexOf(notifications.find(n => n.id === task.id)),1)
      setNotifications([...notifications]);
    }
    setOpenNotification(null);
  };


  useEffect(() => {
    setNotificationsAmt(notifications.filter(notification => notification.newlyAdded).length)
  }, [notifications])

  SignalRTaskContext.useSignalREffect("ReceiveTask", /***
      * @param {Task} task
      */
    (user, task) => {
      let notificationAlreadyInList = notifications
        .map(taskNotification => taskNotification.id)
        .includes(task.id);

      if (!notificationAlreadyInList) {
        task.newlyAdded = true;
        notifications.push(task)
        setNotifications([...notifications])
      }
    }, [])

  return (<>
    <AuthenticatedTemplate>
      <IconButton onClick={handleClickNotification}>
        {notifications && notifications.length > 0 &&
          <Badge badgeContent={notificationsAmt} color="error" overlap="circular" variant="standard">
            <Notifications />
          </Badge>
        }
      </IconButton>
      <Popper
        open={!!openNotification}
        anchorEl={openNotification}
        placement="left"
        sx={{ zIndex: 3, marginTop: "3rem !important" }}
      >
        <Paper>
          <ClickAwayListener onClickAway={handleCloseNotification}>
            <MenuList role="menu">
              {
                notifications.map(notification =>
                  <MenuItem key={`${notification.id}-${notification.updated}`} onClick={(event) => handleCloseNotification(event, notification)} style={{ fontWeight: 400 }}>
                    {notification.description}
                  </MenuItem>
                )
              }
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>

    </AuthenticatedTemplate>
  </>);
}