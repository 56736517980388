import React, { useState } from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
import AhrAvatar from "../Badge/AhrAvatar";
import { CircularProgress, FilledInput, FormControl, IconButton, InputAdornment, InputLabel } from "@mui/material/index";
import { Send } from "@mui/icons-material";
import { deleteComment, getCommentsTaskId, postComment } from "services/taskService";
import { TaskStatus } from "enums";
import TaskCommentRow from "./TaskCommentRow";
import { SignalRTaskNoteContext } from "App";


export default function TaskComments(props) {
    /**
   * @type {[TaskNote[],React.Dispatch<any>]}
   */
    // @ts-ignore
    const [taskNotes, setTaskNotes] = useState(props.taskNotes.sort(sortByDateDescending))
    const [itemsToBeDeleted, setItemsToBeDeleted] = useState([])
    const [isPosting, setIsPosting] = useState(false)

    SignalRTaskNoteContext.useSignalREffect("ReceiveTaskNote", (user, updatedTaskNote)=>{
            let isCurrentTask = props.taskId == updatedTaskNote.taskId
            if(isCurrentTask){
                getCommentsTaskId(taskId)
                .then((comments) => {
                    setTaskNotes([...comments
                        .filter(task => task.statusTypeId !== TaskStatus.DELETED)
                        .sort(sortByDateDescending)
                   ]);
                })
            }
    }, [])

    /** @type {number} */
    let taskId = props.taskId;

    const noteInputSpacing = "2rem"

    function handleDeleteComment(taskNoteId) {
        if(!itemsToBeDeleted.includes(taskNoteId)){
            setItemsToBeDeleted([...itemsToBeDeleted,taskNoteId])
        }
        deleteComment(taskNoteId)
            .then(() => getCommentsTaskId(taskId))
            .then((comments) => {
                setTaskNotes(comments
                    .filter(task => task.statusTypeId !== TaskStatus.DELETED)
                    .sort(sortByDateDescending)
                );
            })
            .finally(() => {
                itemsToBeDeleted.splice(itemsToBeDeleted.indexOf(taskNoteId),1)
                setItemsToBeDeleted([...itemsToBeDeleted])
            })
    }

    function TaskCommentBox() {
        const [commentText, setCommentText] = useState("");
        function handleInputChange(e) {
            let value = e.currentTarget.value;
            setCommentText(value);
        }

        function handleKeyDown(e) {
            if (e.keyCode === 13 && commentText.length > 0) {
                handleSendComment();
            }
        }

        function handleSendComment() {
            /** @type {TaskNote} */
            let newNote = {
                note: commentText,
                taskId: taskId
            }
            setIsPosting(true)
            postComment(newNote)
                .then(() => getCommentsTaskId(taskId))
                .then((comments) => {
                    return setTaskNotes(comments
                        .filter(task => task.statusTypeId !== TaskStatus.DELETED)
                        .sort(sortByDateDescending)
                    );
                })
                .finally(() => {
                    setIsPosting(false);
                })
        }

        return <FormControl sx={{
            marginTop: "1rem",
            marginBottom: "1rem",
            marginLeft: noteInputSpacing,
            paddingRight: noteInputSpacing,
            width: `calc(100% - ${noteInputSpacing})`
        }}>
            <InputLabel htmlFor="filled-adornment-add-comment">Add Comment...</InputLabel>
            <FilledInput
                id="filled-adornment-add-comment"
                type="text"
                disabled={isPosting}
                value={commentText}
                onKeyDown={handleKeyDown}
                onChange={handleInputChange}
                endAdornment={
                    <InputAdornment position="end" title="Send">
                        {
                            !isPosting &&
                            <IconButton aria-label="Send comment" onClick={handleSendComment} edge="end">
                                <Send />
                            </IconButton>
                        }
                        {
                            isPosting &&
                            <CircularProgress color="inherit" style={{ height: "25px", width: "25px" }} />
                        }
                    </InputAdornment>
                } />
        </FormControl>
    }

    //Styling for if no comments are added
    if (!taskNotes || (taskNotes && taskNotes.length === 0)) {
        return <>
            <List>
                <ListItem>
                    <ListItemAvatar>
                        <AhrAvatar />
                    </ListItemAvatar>
                    <ListItemText
                        secondary={
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                No comments added...
                            </Typography>
                        } />
                </ListItem>
                <Divider variant="inset" component="li" />
            </List>
            <TaskCommentBox />
        </>
    }

    return (
        <>
            <List sx={{ width: '94%', left: "2%", bgcolor: 'background.paper', maxHeight: "30vh", overflowY: "scroll" }}>
                {
                    props.user && props.user.mail && taskNotes.map(taskNote => {
                        return <TaskCommentRow itemsToBeDeleted={itemsToBeDeleted} key={`${taskNote.id}-${taskNote.updated}`} userEmail={props.user.mail} taskNote={taskNote} deleteEvent={handleDeleteComment} />
                    })
                }
            </List>
            <TaskCommentBox />
        </>
    );


    function sortByDateDescending(a, b) {
        // @ts-ignore
        return new Date(b.updated) - new Date(a.updated);
    }
}