import React from "react";
import { getProfilePic } from "services/userService";
// @ts-ignore
import { Person } from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import { ListItemAvatar } from "@mui/material/index";
import { useQuery } from "react-query";

export default function AhrAvatar({userId="", sidebar=false, style={}, inline=false}){
    if(userId == null || userId == undefined){
        userId = "";
    }
    const { data } = useQuery(["AhrAvatar",userId.toLowerCase()], ()=> getProfilePic(userId), 
        { 
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            staleTime: 60 * (60 * 1000)
        }
    ); 

    return (
        sidebar ? <div style={{...style, width: 34, height: 34, marginLeft: "23px", marginRight: "11px", display: "flex", zIndex: 5, overflow: "hidden", transition: "all 300ms linear", alignItems: "center"}}>
            {
                data ? <img style={{width: 34, height: 34}} src={data}/> : <Person style={{...style, width: 34, height: 34}}/>
            }
            </div>   
        :
        inline == false ?
        <ListItemAvatar style={style}>
            <Avatar alt={userId} src={data} />
        </ListItemAvatar>
        :
        <Avatar alt={userId} src={data} />
    )
}