import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

// core components
import AdminNavbarLinks from "./AdminNavbarLinks";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";
import MoreVert from "@mui/icons-material/MoreVert";
import ViewList from "@mui/icons-material/ViewList";
import Button from "@mui/material/Button";
import Hidden from "@mui/material/Hidden";
import { IconButton } from "@mui/material";

const useStyles = makeStyles(styles);

export default function AdminNavbar(props) {
  const classes = useStyles();
  const { color, rtlActive, brandText } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color,
  });
  const sidebarMinimize =
    classes.sidebarMinimize +
    " " +
    cx({
      [classes.sidebarMinimizeRTL]: rtlActive,
    });

    const iconStyle={
      fontSize: "24px",
      fontWeight: 400,
      height: "18px",
      width: "18px",
      color: "rgb(153, 153, 153)",
      fill: "rgb(153, 153, 153)"
    }
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown implementation="css">
          <div className={sidebarMinimize}>
            {props.miniActive ? (
              <IconButton
                variant="outlined"
                onClick={props.sidebarMinimize}
                sx={{
                  "svg": iconStyle,
                  boxShadow: "0 2px 2px 0 rgb(153 153 153 / 14%), 0 3px 1px -2px rgb(153 153 153 / 20%), 0 1px 5px 0 rgb(153 153 153 / 12%)",
                  background: "white"
                }}
              >
                <ViewList className={classes.sidebarMiniIcon} />
              </IconButton>
            ) : (
              <IconButton
                variant="outlined"
                onClick={props.sidebarMinimize}
                sx={{
                  "svg": iconStyle,
                  boxShadow: "0 2px 2px 0 rgb(153 153 153 / 14%), 0 3px 1px -2px rgb(153 153 153 / 20%), 0 1px 5px 0 rgb(153 153 153 / 12%)",
                  background: "white"
                }}
              >
                <MoreVert className={classes.sidebarMiniIcon} />
              </IconButton>
            )}
          </div>
        </Hidden>
        <div className={classes.flex}>
          <Button sx={{
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            color: "rgb(85,85,85)",
            fontSize: "18px",
            fontWeight: 300,
            textTransform: "none",
            padding: "12px 30px"
          }}
          >
            {brandText}
          </Button>
        </div>
        <Hidden smDown implementation="css">
          <AdminNavbarLinks />
        </Hidden>
        {/* <Hidden mdUp implementation="css">
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden> */}
      </Toolbar>
    </AppBar>
  );
}

AdminNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  brandText: PropTypes.string,
  miniActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  sidebarMinimize: PropTypes.func,
};
