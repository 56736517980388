export function logError(err){
    let message = ""
    if(err && err.message){
        message = err.message
    }
    if(typeof(err) == 'string'){
        message = err;
    }

    switch(message){
        case "Failed to fetch": 
            if(location.origin == "http://localhost:3000"){
                console.table({
                    message,
                    fixA: "You may need to get off VPN / Network and hotspot",
                    fixB: "Restart Visual Studio",
                    fixC: "Clear cookies"
                })
            }
            break;
        default:
            break;
    }
}