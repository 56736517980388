import React, { useEffect, useState } from "react"
import { updateComment } from "services/taskService"
import { Edit, Delete, Cancel } from "@mui/icons-material/index"
import { ListItem, ListItemAvatar, ListItemText, Typography, IconButton, Divider, CircularProgress, FormControl, InputAdornment, Input } from "@mui/material/index"
import AhrAvatar from "../Badge/AhrAvatar"
import { Colors } from "enums"

export default function TaskNoteRow(props) {
    /**
   * @type {[TaskNote,React.Dispatch<any>]}
   */
    const [taskNote, setTaskNote] = useState(props.taskNote)
    const [userEmail] = useState(props.userEmail)
    const [isDeleting, setIsDeleting] = useState(false)
    const [isPosting, setIsPosting] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [commentEditString, setCommentEditString] = useState("");

    useEffect(()=>{
        if(props.itemsToBeDeleted.includes(taskNote.id)){
            setIsDeleting(true)
        }
        else{
            setIsDeleting(false)
        }
    },[props.itemsToBeDeleted])

    function handleDeleteComment(taskNoteId){
        props.deleteEvent(taskNoteId)
    }

    function handleEditComment(comment){
        setCommentEditString(comment);
        setIsEditing(true);
    }

    function handleInputChange(e){
        let value = e.currentTarget.value;
        setCommentEditString(value);
    }
    
    function handleKeyDown(e){
        if(e.keyCode === 13 && commentEditString.length > 0){
            handleSendComment();
        }
    }

    function handleSendComment(){
        /** @type {TaskNote} */
        let updatedNote = {
            ...taskNote,
            note: commentEditString
        }
        setIsPosting(true)
        updateComment(updatedNote)
        .then((comments)=>{
            return setTaskNote(updatedNote);
        })
        .finally(()=>{
            setIsPosting(false);
            setIsEditing(false);
        })
    }

    function handleCancelComment(){
        setIsEditing(false);
    }

    return (
    <React.Fragment key={taskNote.id.toString()}>
        <ListItem alignItems="flex-start" sx={{ opacity: isDeleting ? 0.5 : 1}}>
            <ListItemAvatar>
                <AhrAvatar userId={taskNote.userId} />
            </ListItemAvatar>
            { !isEditing &&
                <ListItemText
                    primary={taskNote.userId}
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                {taskNote.note}
                            </Typography>
                            {
                            userEmail.toLowerCase() === taskNote.userId.toLowerCase() && !isDeleting &&
                                <React.Fragment>
                                    <IconButton
                                        title="edit"
                                        aria-label="Edit comment"
                                        onClick={() => { handleEditComment(taskNote.note) }}
                                        sx={{
                                            right: "40px",
                                            bottom: "16px",
                                            marginRight: "1rem",
                                            position: "absolute",
                                            ":hover": {
                                                color: Colors.Blue,
                                                cursor: "pointer"
                                            }
                                        }}
                                    >
                                        <Edit />
                                    </IconButton>
                                    <IconButton
                                        title="delete"
                                        aria-label="Delete comment"
                                        onClick={() => { handleDeleteComment(taskNote.id) }}
                                        sx={{
                                            right: "20px",
                                            bottom: "16px",
                                            position: "absolute",
                                            ":hover": {
                                                color: Colors.Red,
                                                cursor: "pointer"
                                            }
                                        }}
                                    >
                                        <Delete />
                                    </IconButton>
                                </React.Fragment>
                            }
                            {
                                userEmail.toLowerCase() === taskNote.userId.toLowerCase() && isDeleting &&
                                <CircularProgress 
                                color="inherit"
                                sx={{
                                    right: "20px",
                                    bottom: "16px",
                                    position: "absolute",
                                }}
                                 />
                            }
                        </React.Fragment>
                    } />
            }
            {
                isEditing &&
                <FormControl sx={{
                    top: "20px", 
                    width: `100%`,
                    paddingRight: "1rem",
                    }}>
                    <Input
                    type="text" 
                    disabled={isPosting}
                    value={commentEditString}
                    onKeyDown={handleKeyDown}
                    onChange={handleInputChange}
                    endAdornment={
                        <InputAdornment position="end" title="Send">
                            {
                                !isPosting &&
                                    <IconButton sx={{
                                        position: "relative",
                                        bottom: "5px",
                                        ":hover": {
                                            color: "red",
                                            cursor: "pointer"
                                        }
                                    }}
                                        aria-label="Send comment" 
                                        onClick={handleCancelComment} 
                                        edge="end">
                                        <Cancel/>
                                    </IconButton>
                            }
                            {
                                isPosting &&
                                <CircularProgress color="inherit" style={{height: "25px", width: "25px"}}/>
                            }
                        </InputAdornment>
                    }/>
                </FormControl>
            }
        </ListItem>
        <Divider variant="inset" component="li" />
    </React.Fragment>
    )
}