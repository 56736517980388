import React from "react";
import { List } from "@material-ui/core";
import TaskActionItemCustom from "./TaskActionItemCustom";
import TaskActionItem from "./TaskActionItem";

/**
 * @param {Object} props
 * @param {TaskActionItem[]} props.taskActionItems
 * @param {TaskActionItemCustom[]} props.taskActionItemCustoms
 * @param {Function} props.addToProjectsEvent
 * @param {Function} props.acknowledgeRiskEvent
 */
export default function TaskActionItems({taskActionItems,taskActionItemCustoms,addToProjectsEvent, acknowledgeRiskEvent }){
    return( 
        <List dense disablePadding>
        { ((taskActionItemCustoms && taskActionItemCustoms.length === 0) || !taskActionItemCustoms) && taskActionItems && taskActionItems.length > 0 &&
          taskActionItems.map(actionItem => <TaskActionItem key={actionItem.id.toString()} actionItem={actionItem} addToProjectsEvent={addToProjectsEvent} acknowledgeRiskEvent={acknowledgeRiskEvent}/>)
        }
        { taskActionItemCustoms && taskActionItemCustoms.length > 0 &&
          taskActionItemCustoms.map(actionItem => <TaskActionItemCustom key={actionItem.id.toString()} actionItem={actionItem} addToProjectsEvent={addToProjectsEvent} acknowledgeRiskEvent={acknowledgeRiskEvent}/>)
        }
      </List>
    )
}