// @ts-nocheck
/*!

=========================================================
* Material Dashboard PRO React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import "assets/scss/material-dashboard-pro-react.scss?v=1.10.0";

//Split Auth to separate file
//=========AUTH=========
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
//=========AUTH=========

import { App } from "./App.jsx";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
// const accounts = msalInstance.getAllAccounts();

Sentry.init({
  dsn: "https://f246bb3ce4064dc5987b1204a0df41a9@o401558.ingest.sentry.io/4504717834190848",
  integrations: [new BrowserTracing()],
  environment: "production",

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

export const queryClient = new QueryClient({});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <App instance={msalInstance}/>
    <ReactQueryDevtools initialIsOpen={true} />
  </QueryClientProvider>
  ,
  document.getElementById("root")
);
