import React, { createContext } from "react";
import { createSignalRContext } from "react-signalr";

import { MsalProvider } from "@azure/msal-react";

import AdminLayout from "layouts/Admin.js";

import { loginRequest } from "authConfig";
import { EventType } from "@azure/msal-browser";
import lscache from "lib/lscache";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { getTasksToken } from "services/tokenService";
import { createTheme, ThemeProvider } from "@mui/material";
import COLOURS from "assets/jss/colours";
import * as Sentry from "@sentry/react";
import useFilterStore from "useFilterStore";

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be 
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication 
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the 
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */

export const SignalRTaskContext = createSignalRContext();
export const SignalRTaskNoteContext = createSignalRContext();
export const AlertContext = createContext({ 
  alertData: {},
  setAlertData : () => {}
})

export const App = ({ instance }) => {
  console.log("App Rendered")

  let searchParams = new URLSearchParams(window.location.search);
  let taskTypeParam = searchParams.get("taskType");

  const accounts = instance.getAllAccounts();
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [tokenHeaders, setTokenHeaders] = React.useState(null);
  const setTaskListTypeFilter = useFilterStore(state => state.setTaskListTypeFilter);
  const taskListTypeFilter = useFilterStore(state => state.taskListTypeFilter);

  
  if((!taskListTypeFilter || !taskListTypeFilter.length) && taskTypeParam){
    setTaskListTypeFilter([taskTypeParam])
  }
  

  if (accounts.length > 0) {
    instance.setActiveAccount(accounts[0]);
    lscache.setBucket("Account");
    lscache.set("LocalAccountId", accounts[0].localAccountId, 525600000);
    if (!isAuthenticated) {
      setIsAuthenticated(true);
    }
  }
  else {
    instance.loginRedirect(loginRequest);
  }

  instance.addEventCallback((event) => {
    // set active account after redirect
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      instance.setActiveAccount(account);
      if (!isAuthenticated) {
        setIsAuthenticated(true);
      }
    }
  }, error => {
    console.log('error', error);
  });

  // handle auth redired/do all initial setup for msal
  instance.handleRedirectPromise()
    .then(authResult => {
      // Check if user signed in 
      const account = instance.getActiveAccount();
      if (!account) {
        // redirect anonymous user to login page 
        instance.loginRedirect(loginRequest);
      }
      else {
        lscache.setBucket("Account");
        if(account.username){
          Sentry.setUser({ id: account.username })
        }
        lscache.set("LocalAccountId", account.localAccountId, 525600000);
      }
    })
    .catch(err => {
      // TODO: Handle errors
      console.log(err);
    });

  function getBearerToken() {
    return getTasksToken().then(token => {
      setTokenHeaders(token.accessToken);
      let res =  token.headers.get("Authorization")
      return res.slice(7,res.length)
    })
  }

  const theme = createTheme({
    palette:{
      primary: {
        main: COLOURS.DARK_BLUE
      },
      secondary: {
        main: COLOURS.RED
      }
    }
  });

  return (
    <BrowserRouter>
    <ThemeProvider theme={theme}>
      <MsalProvider instance={instance}>
        {isAuthenticated &&
          <Switch>
            <>
              <Route path="/auth"></Route>
              <Route path="/admin">
                <SignalRTaskContext.Provider
                  accessTokenFactory={getBearerToken}
                  headers={tokenHeaders}
                  dependencies={[isAuthenticated]}
                  url={"https://notification-centre-tasks-ms-2-w.azurewebsites.net/taskHub"}>
                  <SignalRTaskNoteContext.Provider
                    accessTokenFactory={getBearerToken}
                    headers={tokenHeaders}
                    dependencies={[isAuthenticated]}
                    url={"https://notification-centre-tasks-ms-2-w.azurewebsites.net/taskNoteHub"}>
                    <AdminLayout />
                  </SignalRTaskNoteContext.Provider>
                </SignalRTaskContext.Provider>
              </Route>
              <Redirect from="/" to="/admin/notifications" />
            </>
          </Switch>
        }
      </MsalProvider>
    </ThemeProvider>
    </BrowserRouter>
  );
}
