import { create } from 'zustand'

const useMultiSelectStore = create((set)=>({
    enableMultiSelect: false,
    setEnableMultiSelect: (enableMultiSelect) => set({enableMultiSelect}),
    selectedTasks: {},
    setSelectedTasks: (selectedTasks) => set({selectedTasks}),
    lastSelectedTask: {
        index: null,
        checked: false
    },
    setLastSelectedTask: (lastSelectedTask) => set({lastSelectedTask}),
    currentlyHoveredTask: {},
    setCurrentHoveredTask: (currentlyHoveredTask) => set({currentlyHoveredTask}),
    currentTasksHovered: {},
    setCurrentTasksHovered: (currentTasksHovered) => set({currentTasksHovered}),
    isTaskDismissed: {},
    setIsTaskDismissed: (isTaskDismissed) => set({isTaskDismissed}),
    isDismissing: {},
    setIsDismissing: (isDismissing) => set({isDismissing})
}))

/**
 * @returns {{[key: string]: number}}
 */
export const selectCurrentTasksHovered = (state) => state.currentTasksHovered;
/**
 * @returns {{[key: string]: boolean;}}
 */
export const getSelectedTasks = (state) => state.selectedTasks;


export default useMultiSelectStore;