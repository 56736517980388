import { msalInstance as publicClientApplication } from "../index"
import { protectedResources } from "../authConfig"

export const getToken = async function getToken(scopes) {    
    let activeAccount = publicClientApplication.getActiveAccount();
    return  publicClientApplication.acquireTokenSilent({
            scopes,
            account: activeAccount
        })
        .then(tokenResponse => {
            let accessToken = tokenResponse.accessToken
            const headers = new Headers();
            const bearer = `Bearer ${accessToken}`;

            headers.append("Authorization", bearer);
            headers.append("Accept", '*/*');
            headers.append("Content-Type", 'application/json; charset=utf-8');

            const options = {
                method: "GET",
                headers: headers,
            };

            return options
        })
}

export const getTasksToken = async function getTasksToken() {    
    return getToken(protectedResources.tasksApi.scopes);
}

export const getGraphToken = async function getGraphToken() {    
    return getToken(protectedResources.graphMe.scopes);
}