import { create } from 'zustand'

const useFilterStore = create((set)=>({
    newTask: null,
    setNewTask: (newTask) => set({newTask}),
    filterToggle: false,
    setFilterToggle: (filterToggle) => set({filterToggle}),
    taskListStatusFilter: [],
    setTaskListStatusFilter: (taskListStatusFilter) => set({taskListStatusFilter}),
    taskListTypeFilter: [],
    setTaskListTypeFilter: (taskListTypeFilter) => set({taskListTypeFilter}),
    officeListFilter: [],
    setOfficeListFilter: (officeListFilter) => set({officeListFilter}),
    searchText: "",
    setFilters: (taskListStatusFilter, officeListFilter, searchText) => set({taskListStatusFilter, officeListFilter, searchText})
}))

export default useFilterStore;