import React from "react";

import TaskTypes from "components/display/Tasks/TaskTypes";
import TaskList from "components/display/Tasks/TaskList";
export default function NotificationsPage() {
  return (
    <>
      <TaskTypes />
      <TaskList />
    </>
  );
}