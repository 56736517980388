export var TaskType = {
    NewStarter: 1,
    ProjectCreated: 2,
    ProjectOnboarding: 3,
    ProjectUpdate: 16,
    DocumentPosted: 4,
    DocumentApproved: 5,
    DocumentRejected: 6,
    MilestoneAchieved: 7,
    MilestoneDue: 8,
    MilestoneMissed: 9,
    RiskAdded: 10,
    StageDates: 11,
    BidProjectWin: 12,
    ProjectHandover: 13,

    //Below: To be used for custom notifications
    FunctionalGroup: 14,
    SoftwareUpdate: 15,
    NewsUpdate: 17,
    DisasterRecoveryUpdate: 18,
    ConfirmedLeaver: 19,
    PolicyUpdate: 20,
}

export var ActionItemType ={
    AcknowledgeOccupationalRisk: 17,
    OccupationalRisk: 19,
    ExistsInMyProjects: 998,
    AddToMyProjects: 999
}

export var TaskStatus = {
    /** Default */
    NEW: 1,
    ONHOLD: 2,
    DELETED: 3,
    /** Task: Hide */
    DISMISSED: 4,
    /** Task: Read (as opposed to unread) */
    UPDATED: 5,
}

export var Colors = {
    Red: "#D25D48",
    Blue: "#4799B2",
    DarkBlue: "#00566F",
    Green: "#22905D",
    Background: "#eeeeee"
}