import { getTasksToken, getGraphToken } from "./tokenService";
import lscache from "lib/lscache";

import azureGatewayService from "./azureGatewayService"

const { post } = azureGatewayService(getTasksToken);


/**
 * @returns {Promise<ADUser>}
 */
export const getGraphData = async function getGraphData() {
    return getGraphToken()
        .then(options => fetch("https://graph.microsoft.com/v1.0/me", options))
        .then(res => res.json())
        .then(res=>{
            if(res && res.error){
                throw res;
            }
            if(res && res.code !== "InvalidAuthenticationToken"){
                return res;
            }
        })
}

export const getProfilePic = async function getProfilePic(userId) {
    userId = !!userId ? userId.toLowerCase() : "";
    if(!userId){
        return Promise.resolve("");
    }
    return new Promise((resolve,reject)=>{
        getGraphToken()
            .then(options => fetch(`https://graph.microsoft.com/v1.0/users/${userId}/photo/$value`, { ...options}))
            .then(res => { //If there is an error it seems to be embedded within a 20X OK response for some reason ¯\_(ツ)_/¯
                if(!res.ok){                    
                    throw {
                        statusText:res.statusText,
                        status: res.status
                    }
                }
                return res.blob()
            })
            .then(picBlob=>{
                try{
                    const reader = new FileReader();
                    reader.readAsDataURL(picBlob);
                    reader.onload = () => {
                        resolve(reader.result);
                    }
                }
                catch(err){
                    resolve("");
                    // reject(err);
                }
            })
            // .catch(reject);
            .catch(()=> resolve("")); //If there is no profile pic, just resolve with an empty string
    })
}

export const getLocallyStoredAccountId = function(){
    lscache.setBucket("Account");
    return lscache.get("LocalAccountId");
}

/**
 * @param {SearchTerm} searchTerm
 * @returns {Promise<SearchResult[]>}
 */
 export const searchUsers = async function getTaskActionItemCustoms(searchTerm) {
    return post(`/Users`, searchTerm)
}

/**
 * @param {SearchTerm} searchTerm
 * @returns {Promise<SearchResult[]>}
 */
 export const searchGroups = async function getTaskActionItemCustoms(searchTerm) {
    return post(`/Groups`, searchTerm)
}