/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";

export default function Footer(props) {
  return (
    <footer style={{ bottom: 0, padding: "15px 0", zIndex: 4, borderTop: "1px solid #e7e7e7", fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', fontWeight: 300, lineHeight: "1.5em" }}>
      <div style={{ zIndex: 3, position: "relative", marginLeft: "auto", marginRight: "auto", paddingLeft: 15, paddingRight: 15 }}>
        <p style={{ margin: 0, padding: 15, fontSize: 14  }}>
          &copy; {1900 + new Date().getYear()}{" "}
          AHR
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool,
};
