import { Person as PersonIcon, Business as BusinessIcon, PlaylistAddCheck, DateRange, CallMerge, Notifications } from "@material-ui/icons";
import { TaskType } from "enums";
import React from "react";
import { BrowserUpdated, CalendarMonth, DoorBack, Event, EventAvailable, EventBusy, Gavel, Group, Handshake, Newspaper, Policy, PostAdd, Report, Storage, SystemUpdate, ThumbDown, ThumbUp } from "@mui/icons-material/index";

/**
  * @param {Object} props 
  * @param {Number} props.taskType 
  * @returns 
  */
export default function TaskIcon({taskType}, style={}) {
    switch (taskType) {
        case TaskType.NewStarter:
            return <PersonIcon style={style}/>
        case TaskType.ProjectCreated:
            return <BusinessIcon style={style}/>
        case TaskType.ProjectOnboarding:
            return <CallMerge style={style}/>
        case TaskType.DocumentPosted:
            return <PostAdd style={style}/>
        case TaskType.DocumentApproved:
            return <ThumbUp style={style}/>
        case TaskType.DocumentRejected:
            return <ThumbDown style={style}/>
        case TaskType.MilestoneAchieved:
            return <EventAvailable style={style}/>
        case TaskType.MilestoneDue:
            return <Event style={style}/>
        case TaskType.MilestoneMissed:
            return <EventBusy style={style}/>
        case TaskType.RiskAdded:
            return <Report style={style}/>
        case TaskType.StageDates:
            return <CalendarMonth style={style}/>
        case TaskType.BidProjectWin:
            return <Gavel style={style}/>
        case TaskType.ProjectHandover:
            return <Handshake style={style}/>
        case TaskType.FunctionalGroup:
            return <Group style={style}/>
        case TaskType.SoftwareUpdate:
            return <SystemUpdate style={style}/>
        case TaskType.ProjectUpdate:
            return <BrowserUpdated style={style}/>
        case TaskType.NewsUpdate:
            return <Newspaper style={style}/>
        case TaskType.DisasterRecoveryUpdate:
            return <Storage style={style}/>
        case TaskType.ConfirmedLeaver:
            return <DoorBack style={style}/>
        case TaskType.PolicyUpdate:
            return <Policy style={style}/>
        default:
            return <Notifications style={style} />
    }
}