import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Collapse, Divider, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { Colors, TaskStatus } from "enums";
import TaskComments from "../Tasks/TaskComments";
import "./AhrNotification.css"
import TaskIcon from "../Tasks/TaskIcon";
import { acknowledgeRisk, addToMyProjects, getTaskActionItemCustoms, getTaskActionItems, updateTask, updateTaskPinned, updateTasks } from "services/taskService";
import { AvatarGroup, CircularProgress, IconButton, Checkbox } from "@mui/material/index";
import AhrAvatar from "../Badge/AhrAvatar";
import { Done, PushPin, PushPinOutlined } from "@mui/icons-material";
import TaskActionItems from "../Tasks/TaskActionItems";
import useFilterStore from "useFilterStore";
import useMultiSelectStore, { getSelectedTasks } from "useMultiSelectStore";

/**
 * @param {Object} param0 
 * @param {Task} param0.task
 * @param {ADUser} param0.user
 * @param {number} param0.index
 * @param {{[key: string]: number;}} param0.currentTasksHovered Give a notification awareness if it is hovered ["task.id": index]
 * @param {Object} param0.taskIndexMappingRef
 * @param {React.MutableRefObject<boolean>} param0.shiftPressed
 * @returns 
 */
export default function AhrNotification({ task, user, index=0, currentTasksHovered={}, taskIndexMappingRef, shiftPressed }) {
  /**
   * @type {[Boolean,React.Dispatch<any>]}
   */
  const [open, setOpen] = useState(false)
  /**
   * @type {[Boolean,React.Dispatch<any>]}
   */
  const [manualToggle, setManualToggle] = useState(false)
  /**
   * @type {[Boolean,React.Dispatch<any>]}
   */
  const [isPinned, setIsPinned] = useState(task.pinned)
  /**
   * @type {[Boolean,React.Dispatch<any>]}
   */
  const [isHoverPinned, setIsHoverPinned] = useState(false);
  /**
   * @type {[number,React.Dispatch<number>]}
   */
  // @ts-ignore
  let [taskStatus, setTaskStatus] = useState(task.statusTypeId || TaskStatus.NEW);
  /**
   * @type {[boolean,React.Dispatch<any>]}
   */
  let [commentsDisabled, setCommentsDisabled] = useState(false);
  let [actionItemsLoading, setActionItemsLoading] = useState(false);
  let [taskActionItemsLoading, setTaskActionItemsLoading] = useState(false);
  let [taskActionItems, setTaskActionItems] = useState([]);
  let [customActionItemsLoading, setCustomActionItemsLoading] = useState(false);
    /**
   * @type {[any[],React.Dispatch<any>]}
   */
  let [taskActionItemCustoms, setTaskActionItemCustoms] = useState([]);
  const newTask = useFilterStore(state => state.newTask);
  const setNewTask = useFilterStore(state => state.setNewTask);
  const enableMultiselect = useMultiSelectStore(state => state.enableMultiSelect);
  const setEnableMultiselect = useMultiSelectStore(state => state.setEnableMultiSelect);
  const selectedTasks = useMultiSelectStore(getSelectedTasks);
  const setSelectedTasks = useMultiSelectStore(state => state.setSelectedTasks);
  const setCurrentHoveredTask = useMultiSelectStore(state => state.setCurrentHoveredTask);
  const lastSelectedTask = useMultiSelectStore(state => state.lastSelectedTask);
  const setLastSelectedTask = useMultiSelectStore(state => state.setLastSelectedTask);
  const setIsTaskDismissed = useMultiSelectStore(state => state.setIsTaskDismissed);
  const isTaskDismissed = useMultiSelectStore(state => state.isTaskDismissed);
  const isDismissing = useMultiSelectStore(state => state.isDismissing);
  const setIsDismissing = useMultiSelectStore(state => state.setIsDismissing);

  const subTaskPaperStyle = {
    borderRadius: "10px",
    margin: "0.5rem"
  }

  const iconButtonPadding = "1rem";
  const listItemStyle = { 
      paddingTop: 0, paddingBottom: 0, paddingLeft: 0, paddingRight: 0, 
      background: currentTasksHovered && currentTasksHovered[task.id] ? "rgb(235,235,235)" : !!selectedTasks[task.id] ? "rgba(240,248,255)" : newTask?.id == task.id ? "rgb(210,210,255)" : "", 
      transition: "background 0.75s", 
      opacity: task.statusTypeId == TaskStatus.DISMISSED ? 0.5 : 1,
      border: lastSelectedTask.index == index ? "1px dotted gray" : "none"
  }

  useEffect(() => {
    taskIndexMappingRef.current[index] = task.id;
  },[])

  useEffect(()=>{
    function getData(){
      setCustomActionItemsLoading(true)
      getTaskActionItemCustoms(task.id)
        .then(setTaskActionItemCustoms)
        .finally(()=>setCustomActionItemsLoading(false));
      
      setActionItemsLoading(true)
      getTaskActionItems(task.id)
        .then(setTaskActionItems)
        .finally(()=>setActionItemsLoading(false));
    }

    getData()
  },[task.id, open, manualToggle])

  useEffect(()=>{
    if(((actionItemsLoading && taskActionItems.length == 0) || customActionItemsLoading) == true){
      setTaskActionItemsLoading(true)
    }
    else{
      setTaskActionItemsLoading(false)
    }
  },[actionItemsLoading, customActionItemsLoading])

  useEffect(() => {
    let commentsDisabledCheck = ((task.taskMembers && task.taskMembers.length <= 1) ||
      !task.taskMembers) && (!task.taskNotes || task.taskNotes.length === 0)

    if (commentsDisabledCheck != commentsDisabled) {
      setCommentsDisabled(commentsDisabledCheck);
    }
  }, [task.taskMembers, task.taskNotes])

  useEffect(() => {
    setTimeout(() => {
      setNewTask(null);
    }, 1000)
  }, [])

  function toggleTaskChecked(ev){
    if(!shiftPressed.current){
      if(selectedTasks[task.id]){
        let newSelectedTasks = {...selectedTasks};
        delete newSelectedTasks[task.id];
        setSelectedTasks(newSelectedTasks);
        setLastSelectedTask({ index, checked: false});
      }
      else{
        setSelectedTasks({...selectedTasks, [task.id]: true});
        setLastSelectedTask({ index, checked: true});
      }
    }
    else {
      let tempSelectedTasks = {...selectedTasks};      
      let allTrue, allFalse; 

      //Determine if all hovered tasks are selected
      for(var taskToCheck of Object.keys(currentTasksHovered)){
        if(selectedTasks[taskToCheck]){
          allTrue = true;
        }
        else{
          allTrue = false;
          break;        
        }
      }

      //Determine if all hovered tasks are not selected
      for(var taskToCheck of Object.keys(currentTasksHovered)){
        if(!selectedTasks[taskToCheck]){
          allFalse = true;
        }
        else{
          allFalse = false;
          break;        
        }
      }

      //Assign new values to (un)selected tasks
      for(var taskToDismiss of Object.keys(currentTasksHovered)){
          if(allTrue){
            delete tempSelectedTasks[taskToDismiss];
          }
          else if(allFalse){
            tempSelectedTasks[taskToDismiss] = true;
          }
          else{
            if(lastSelectedTask.checked){
              tempSelectedTasks[taskToDismiss] = true;
            }
            else{
              delete tempSelectedTasks[taskToDismiss];
            }
          }
      }

      setSelectedTasks(tempSelectedTasks);
      setLastSelectedTask({ index, checked: Object.values(tempSelectedTasks)[0]});
    }
  }

  function handleClick(ev) {
    if (isDismissing[task.id] || enableMultiselect) {
      toggleTaskChecked(ev);
      return;
    }
    setOpen(!open);
    if (taskStatus == TaskStatus.NEW) {
      setTaskStatus(TaskStatus.UPDATED);
      updateTask(task.id.toString(), TaskStatus.UPDATED)
    }
  }

  function handleDismissClick(event) {
    event.stopPropagation();
    if (!!selectedTasks[task.id]) {
      let taskIsDismissing = {};
      for(var taskToDismiss of Object.keys(selectedTasks)){
        taskIsDismissing[taskToDismiss] = true;
      }
      setIsDismissing(taskIsDismissing);
      
      if(Object.keys(selectedTasks).length > 1){
        updateTasks(Object.keys(selectedTasks), TaskStatus.DISMISSED)
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          setIsDismissing({});
          setEnableMultiselect(false);
          let taskDismissedTemp = {...isTaskDismissed};
          for(var taskToDismiss of Object.keys(selectedTasks)){
            taskDismissedTemp[taskToDismiss] = true;
          }
          setIsTaskDismissed(taskDismissedTemp);
        })
      }
      else if(Object.keys(selectedTasks).length == 1){
        updateTask(task.id.toString(), TaskStatus.DISMISSED)
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          setEnableMultiselect(false);
          setIsDismissing({});
          setIsTaskDismissed({...isTaskDismissed, [task.id]: true});
        })
      }
    }
    else if(!selectedTasks[task.id]){
      setEnableMultiselect(true);
      toggleTaskChecked(event);
    }
  }

  function CircularProgressRowItem() {
    return <IconButton sx={{
      cursor: "wait", marginRight: iconButtonPadding, ":hover": {
        backgroundColor: "rgba(0,0,0,0)"
      }
    }}>
      <CircularProgress color="inherit" style={{ height: "25px", width: "25px" }} />
    </IconButton>
  }

  function handleTaskPin(event) {
    event.stopPropagation();

    setIsPinned(!isPinned)
    updateTaskPinned(task.id, !isPinned)
      .catch((err) => {
        setIsPinned(isPinned)
      })
  }

  function addToMyProjectsEvent() {
    addToMyProjects(task.id)
      .then((res) => {
        setManualToggle(!manualToggle);
      })
  }

  function acknowledgeRiskEvent(actionItemId) {
    return acknowledgeRisk(actionItemId)
      .then((res) => {
        setManualToggle(!manualToggle);
      })
  }

  function handleMouseEnter(){
    setCurrentHoveredTask({index, taskId: task.id});
  }

  if(isTaskDismissed[task.id]){
    return null;
  }

  return (
    <>
      <ListItem onMouseEnter={handleMouseEnter} style={listItemStyle}>
        <ListItemButton onClick={handleClick} style={{
          paddingLeft: 32, paddingRight: 32, paddingTop: 8, paddingBottom: 8,
          cursor: ((taskActionItems && taskActionItems.length > 0) || (taskActionItemCustoms && taskActionItemCustoms.length > 0)) ? 'pointer' : 'default'
        }}>
          {
            enableMultiselect &&
            <ListItemIcon>
              <Checkbox checked={!!selectedTasks[task.id]} onClick={toggleTaskChecked}/>
            </ListItemIcon>
          }
          {
            !enableMultiselect &&
            <ListItemIcon>
              <TaskIcon taskType={task.taskTypeId} />
            </ListItemIcon>
          }
          <ListItemText primary={`${task.name} ${index}`} secondary={task.description} sx={{
            "& .MuiListItemText-primary": {
              fontWeight: taskStatus == TaskStatus.NEW ? "bold" : 400
            },
            "& .MuiListItemText-secondary": {
              fontWeight: taskStatus == TaskStatus.NEW ? "bold" : 400
            },
          }} />
          {
            (task.taskMembers && task.taskMembers.length > 0) &&
            <>
              <AvatarGroup style={{ marginRight: "3rem" }} max={4} title={task.taskMembers.map(tm => tm.userId + "\n").join()}>
                {
                  task.taskMembers.map(taskMember =>
                    <AhrAvatar key={taskMember.userId} userId={taskMember.userId} inline />
                  )
                }
              </AvatarGroup>
            </>
          }
          {
            !isDismissing[task.id] ?
              <>
                {
                  !!selectedTasks[task.id] &&
                  <>
                    <span style={{ marginRight: "1rem", minWidth: "135px" }}>Click again to dismiss</span>                    
                      <IconButton title={task.statusTypeId == TaskStatus.DISMISSED ? "Task already dismissed" : "Dismiss task"} 
                                  disabled={task.statusTypeId == TaskStatus.DISMISSED} 
                                  onClick={handleDismissClick} 
                                  sx={{ cursor: "pointer", marginRight: iconButtonPadding, ":hover": { color: Colors.Green } }}
                                  >
                        <Done />
                      </IconButton>                    
                  </>
                }
                {
                  !selectedTasks[task.id] &&
                  <IconButton title={task.statusTypeId == TaskStatus.DISMISSED ? "Task already dismissed" : "Dismiss task"} disabled={task.statusTypeId == TaskStatus.DISMISSED} onClick={handleDismissClick} sx={{ cursor: "pointer", marginRight: iconButtonPadding, ":hover": { color: Colors.Green } }}>
                    <Done />
                  </IconButton>
                }
              </>
              :
              <CircularProgressRowItem />
          }

          <IconButton title={isPinned ? "Unpin task" : "Pin task"} onClick={handleTaskPin} onMouseOver={() => { setIsHoverPinned(true) }} onMouseLeave={() => { setIsHoverPinned(false) }} sx={{ transform: "rotate(45deg)", cursor: "pointer", marginRight: iconButtonPadding }}>
            {
              isPinned || isHoverPinned ?
                <PushPin />
                :
                <PushPinOutlined />
            }
          </IconButton>
          {open ?
            <IconButton>
              <ExpandLess />
            </IconButton> :
            <IconButton>
              <ExpandMore />
            </IconButton>
          }
        </ListItemButton>
      </ListItem>
      <Divider></Divider>
      <Collapse in={open} sx={{ bgcolor: 'action.hover' }} className='innerFlex'>
        {
          taskActionItemsLoading && (!taskActionItems || taskActionItems.length == 0) && (!taskActionItemCustoms || taskActionItemCustoms.length == 0) &&
          <CircularProgress style={{ margin: "1rem" }} />
        }
        <Paper style={{ flex: commentsDisabled ? 1 : 0.6, ...subTaskPaperStyle }}>
          <TaskActionItems taskActionItems={taskActionItems} taskActionItemCustoms={taskActionItemCustoms} addToProjectsEvent={addToMyProjectsEvent} acknowledgeRiskEvent={acknowledgeRiskEvent} />
        </Paper>
        {!commentsDisabled &&
          <Paper style={{ flex: 0.4, ...subTaskPaperStyle }}>
            <TaskComments user={user} taskId={task.id} taskNotes={task.taskNotes} />
          </Paper>}
      </Collapse>
    </>
  )
}