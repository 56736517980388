import { BookmarkAdd, Check } from "@mui/icons-material";
import { CircularProgress, Divider, IconButton, ListItemButton, ListItemText } from "@mui/material";
import { ActionItemType, Colors } from "enums";
import React from "react";

export default function TaskActionItemCustom({ actionItem, addToProjectsEvent, acknowledgeRiskEvent }) {
  const [riskAcknowedgeLoading, setRiskAcknowedgeLoading] = React.useState(false);

  function addToMyProjects() {
    addToProjectsEvent();
  }

  function acknowledgeRisk(ev) {
    ev.preventDefault();
    ev.stopPropagation();

    setRiskAcknowedgeLoading(true);
    acknowledgeRiskEvent(actionItem.id)
      .finally(() => setRiskAcknowedgeLoading(false));
  }

  return <>
    <a target="_blank" href={actionItem.uri} style={{ width: "100%" }} data-action-item-type-id={actionItem.actionItemTypeId}>
      <ListItemButton sx={{ padding: 0 }}>
        <ListItemText primary={actionItem.note || actionItem.action} sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingTop: "4px",
          paddingBottom: "4px",
          color: "black", ":hover": {
            textDecoration: "underline"
          }
        }} />
        {
          actionItem.actionItemTypeId == ActionItemType.AcknowledgeOccupationalRisk &&
          <IconButton onClick={acknowledgeRisk} title="Acknowledge Risk" sx={{ borderRadius: "0 10% 10% 0", color: "white", background: Colors.Red }}>
            {
              riskAcknowedgeLoading ? <CircularProgress size={20} sx={{ color: "white" }} /> : <Check />
            }
          </IconButton>
        }
        {
          actionItem.actionItemTypeId == ActionItemType.AddToMyProjects &&
          <IconButton onClick={addToMyProjects} title="Add to my projects" sx={{ borderRadius: "0 10% 10% 0", color: "white", background: Colors.Red }}>
            <BookmarkAdd />
          </IconButton>
        }
        {
          actionItem.actionItemTypeId == ActionItemType.ExistsInMyProjects &&
          <IconButton title="Already in my projects" sx={{ borderRadius: "0 0 0 0", color: "white", background: "rgba(0, 0, 0, 0.08)" }}>
            <BookmarkAdd />
          </IconButton>
        }
      </ListItemButton>
    </a>
    <Divider light />
  </>
}