import { getTasksToken } from "./tokenService"
import azureGatewayService from "./azureGatewayService"
import { TaskStatus } from "enums";

const { get, post, put, del } = azureGatewayService(getTasksToken);

/**
 * @returns {Promise}
 */
export const acknowledgeRisk = async (taskId) => await post(`/OccupationalRiskRegister`, { id: taskId })

/**
 * @param {string} taskId
 * @param {number} statusTypeId
 */
export const updateTask = async function updateTask(taskId, statusTypeId) {
    return put(`/tasks/${taskId}`,{statusTypeId})
}

/**
 * @param {string[]} taskIds
 * @param {number} statusTypeId
 */
export const updateTasks = async function updateTask(taskIds, statusTypeId) {
    return put(`/tasks`,{statusTypeId, taskIds})
}

/**
 * @returns {Promise<TaskPagination>}
 */
export const getTasks = async function getTasks(page=1,itemSize=20, taskTypes = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20], statusTypes = [1,2,5], officeIds = [], searchText = "") {
    return post(`/tasksSearch`, { taskTypes, statusTypes, itemSize, page, officeIds, searchText});
}

/**
 * @param {number[]} taskStatuses
 * @returns {Promise<TaskSummary[]>}
 */
export const getTasksSummary = async function getTasksSummary(taskStatuses=[TaskStatus.NEW,TaskStatus.UPDATED], officeIds = [], searchText="") {
    if(taskStatuses.length === 0) {
        taskStatuses = [TaskStatus.NEW,TaskStatus.UPDATED];
    }
    return get(`/UserTaskTypes?statusTypes=${taskStatuses.join("&statusTypes=")}&officeIds=${officeIds.join("&officeIds=")}&searchText=${encodeURIComponent(searchText)}`);
}

/**
 * @returns {Promise<TaskNote[]>}
 */
 export const getCommentsTaskId = async function getCommentsTaskId(taskId) {
    return get(`/Comments/${taskId}`);
}

/**
 * Requires both comment (obj.Note) and TaskId (obj.TaskId)
 * @param {TaskNote} comment
 * @returns {Promise<TaskNote>}
 */
 export const postComment = async function postComment(comment) {
    return post("/Comments",comment);
}

/**
 * @param {TaskNote} comment
 * @returns {Promise<TaskNote>}
 */
 export const updateComment = async function updateComment(comment) {
    return put(`/Comments/${comment.id}`,comment)
}
/**
 * @param {number} taskId
 * @returns {Promise<string>}
 */
 export const deleteComment = async function deleteComment(taskId) {
    return del(`/Comments/${taskId}`)
}

/**
 * @param {POSTCustomTaskDto} task
 * @returns {Promise<string>}
 */
 export const postCustomTask = async function postCustomTask(task) {
    return getTasksToken()
        .then(options => fetch("https://ahrgatewayapi.azurewebsites.net/CustomTasks", {...options, 
            method: 'POST',
            body: JSON.stringify(task)
        }))
        .then(res => res.text())
}

/**
 * @param {number} taskId
 * @returns {Promise<boolean>}
 */
 export const getIsMyProjects = async function addToMyProjects(taskId) {
    return get(`/MyProjects/${taskId}`)
}

/**
 * @param {number} taskId
 * @returns {Promise<boolean>}
 */
 export const addToMyProjects = async function addToMyProjects(taskId) {
    return post(`/MyProjects`,{TaskId: taskId})
}

/**
 * @param {number} taskId
 * @param {boolean} isPinned
 * @returns {Promise<boolean>}
 */
 export const updateTaskPinned = async function addToMyProjects(taskId, isPinned) {
    return put(`/Pinned/${taskId}`, { pinned: isPinned })
}

/**
 * @param {number} taskId
 * @returns {Promise<TaskActionItem[]>}
 */
 export const getTaskActionItems = async function getTaskActionItems(taskId) {
    return get(`/TaskActionItems/${taskId}`)
}

/**
 * @param {number} taskId
 * @returns {Promise<TaskActionItem>}
 */
 export const getTaskActionItemCustoms = async function getTaskActionItemCustoms(taskId) {
    return get(`/TaskActionItems/${taskId}`)
}