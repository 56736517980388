const baseGatewayUrl = "https://ahrgatewayapi.azurewebsites.net"

/**
 * @param {function} scopeFn The GetToken function to be used by the factory
 * @returns {ApiMethods} param0
 */
export default function(scopeFn){
    return {
        get: async function(url, addOptions){
            return scopeFn()
            .then(options => _fetch(`${baseGatewayUrl}${url}`, {
                ...options,
                ...addOptions,
                method: "GET"
            }))
        },
        put: async function(url, body, addOptions){
            return scopeFn()
            .then(options => _fetch(`${baseGatewayUrl}${url}`, {
                ...options,
                ...addOptions,
                body: JSON.stringify(body),
                method: "PUT"
            }))
        },
        post: async function(url, body, addOptions){
            return scopeFn()
            .then(options => _fetch(`${baseGatewayUrl}${url}`, {
                ...options,
                ...addOptions,
                body: JSON.stringify(body),
                method: "POST"
            }))
        },
        del: async function(url, addOptions){
            return scopeFn()
            .then(options => _fetch(`${baseGatewayUrl}${url}`, {
                ...options,
                ...addOptions,
                method: "DELETE"
            }))
        },

    } 
}

/**
 * @param {string} url
 * @param {RequestInit} options
 */
const _fetch = async function(url, options) {
    return fetch(url, options)
        .then(res => {        
            if(!res.ok){
                throw new Error(`${options.method}:${url} - ${res.status} - ${res.statusText}`)
            }
            const contentType = res.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {                
                return res.json();
            } else {
                return res.text()
            }
        })
}