import React from "react";
import { useEffect, useState } from "react";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import CardHeader from "../Card/CardHeader";
import CardIcon from "../Card/CardIcon";
import TaskIcon from "./TaskIcon";
import { TaskType } from "enums";
import COLOURS from "assets/jss/colours";
import useFilterStore from "useFilterStore";

/**
* @param {Object} props
* @param {TaskSummary} props.taskSummary List of tasks
*/
export default function TaskTypeCard({ taskSummary }) {

    const [cardColor, setCardColor] = useState("info");
    const [borderColor, setBorderColor] = useState("");
    const [borderColorFaded, setBorderColorFaded] = useState("");
    const [isSelected, setIsSelected] = useState(false);

    const defaultStyling = {
        minWidth: 200, minHeight: 115, maxWidth: 200, maxHeight: 115, cursor: "pointer",
        borderBottom: isSelected ? `6px solid ${borderColor}` : ""
    }

    const [cardStyling, setCardStyling] = useState(defaultStyling);    
    const taskListTypeFilter = useFilterStore(state => state.taskListTypeFilter);
    const setTaskListTypeFilter = useFilterStore(state => state.setTaskListTypeFilter);    

    useEffect(() => {        
        if (taskListTypeFilter.map(x => x?.toString()).includes(taskSummary.id.toString())) {
            setIsSelected(true);
        }
        else {
            setIsSelected(false);
        }
    }, [taskListTypeFilter])

    //Document posted: #26c6da

    useEffect(() => {
        switch (taskSummary.id) {
            //Documents
            case TaskType.DocumentApproved:
            case TaskType.DocumentPosted:
            case TaskType.DocumentRejected:
                setCardColor("info"); //#26c6da
                setBorderColor("rgb(38, 198, 218)");
                setBorderColorFaded("rgba(38, 198, 218, 0.5)");
                break;
            //Projects
            case TaskType.ProjectCreated:
            case TaskType.ProjectHandover:
            case TaskType.ProjectOnboarding:
            case TaskType.ProjectUpdate:
            case TaskType.BidProjectWin:
            case TaskType.RiskAdded:
            case TaskType.StageDates:
            case TaskType.MilestoneAchieved:
            case TaskType.MilestoneDue:
            case TaskType.MilestoneMissed:
                setCardColor("warning") //#ffa726
                setBorderColor("rgb(255, 167, 38)")
                setBorderColorFaded("rgba(255, 167, 38, 0.5)")
                break;
            case TaskType.SoftwareUpdate:
            case TaskType.FunctionalGroup:
            case TaskType.DisasterRecoveryUpdate:
            case TaskType.ConfirmedLeaver:
            case TaskType.NewStarter:
                setCardColor("success") //#66bb6a
                setBorderColor("rgb(102, 187, 106)")
                setBorderColorFaded("rgba(102, 187, 106, 0.5)")
                break;
            case TaskType.RiskAdded:
            default:
                setCardColor("darkBlue") //#ab47bc
                setBorderColor(COLOURS.DARK_BLUE)
                setBorderColorFaded("rgba(0, 86, 111, 0.5)")
                break;
        }

        setCardStyling({
            ...defaultStyling,
            borderBottom: isSelected ? `6px solid ${borderColor}` : ""
        })
    }, [borderColor, isSelected, borderColorFaded])


    function cardMouseLeave() {
        setCardStyling(defaultStyling)
    }

    function cardMouseEnter() {
        setCardStyling({
            ...defaultStyling,
            borderBottom: `6px solid ${borderColorFaded}`
        })
    }

    function handleOnClick(){
        if(taskListTypeFilter.includes(taskSummary.id)){
            setTaskListTypeFilter([]);            
        }
        else{
            setTaskListTypeFilter([taskSummary.id]);
        }
    }

    return (<>
        {borderColor && <div onClick={() => { handleOnClick() }}>
            <Card style={cardStyling}
                onMouseEnter={cardMouseEnter}
                onMouseLeave={cardMouseLeave}
            >
                <CardHeader icon>
                    <CardIcon color={cardColor} style={{ borderRadius: "35px"}}>
                        <TaskIcon taskType={taskSummary.id} />
                    </CardIcon>
                </CardHeader>
                <CardBody style={{padding: "0.9375rem 15px"}} title={`${taskSummary.name} (${taskSummary.count})`}>
                    <strong style={{maxHeight: 50, overflow: "hidden", lineHeight: "15px", display: "block"}}>{taskSummary.name} ({taskSummary.count})</strong>
                </CardBody>
            </Card>
        </div>}
    </>
    )
}