import { Card, CardContent, Checkbox, ClickAwayListener, FormControl, FormControlLabel, IconButton, List, ListSubheader, Pagination, Skeleton, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React, {  useEffect, useRef, useState } from "react";
import { getGraphData } from "services/userService";
import { getTasks } from "services/taskService";
import AhrNotification from "../Notifications/AhrNotification";
import TaskFilters from "./TaskFilters";
import { useQuery } from "react-query";
import useFilterStore from "useFilterStore";
import useMultiSelectStore, { selectCurrentTasksHovered } from "useMultiSelectStore";
import { Cancel } from "@material-ui/icons";
import { Colors } from "enums";
import { useShallow } from "zustand/react/shallow";

export default function TaskList() {
    //#region State
    /**
     * @type {[Task[],React.Dispatch<any>]}
     */
    const [taskList, setTaskList] = useState([]);
    const [taskDates, setTaskDates] = useState([]);
    /**
    * @type {[number,React.Dispatch<any>]}
    */
    const [pageCount, setPageCount] = useState(10);
    const [paginationNumber, setPaginationNumber] = useState(1);
    const taskListTypeFilter = useFilterStore(useShallow(state => state.taskListTypeFilter));
    const taskListStatusFilter = useFilterStore(useShallow(state => state.taskListStatusFilter));
    const setTaskListTypeFilter = useFilterStore(useShallow(state => state.setTaskListTypeFilter));
    const officeListFilter = useFilterStore(useShallow(state => state.officeListFilter))
    const searchText = useFilterStore(useShallow(state => state.searchText))
    const newTask = useFilterStore(useShallow(state => state.newTask));
    const filterToggle = useFilterStore(useShallow(state => state.filterToggle));

    const {data: user} = useQuery(["GraphData"], getGraphData, {
        staleTime: 10 * (60 * 1000) /* 10 minutes */ ,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false
    });

    /**
     * @type {[TaskPagination,React.Dispatch<any>]}
     */
    const [taskPagination, setTaskPagination] = useState();
    const [isTasksLoading, setIsTasksLoading] = useState(false);
    const selectedTasks = useMultiSelectStore(useShallow(state => state.selectedTasks));
    const setSelectedTasks = useMultiSelectStore(state => state.setSelectedTasks);
    const setLastSelectedTask = useMultiSelectStore(state => state.setLastSelectedTask);
    const setEnableMultiSelect = useMultiSelectStore(state => state.setEnableMultiSelect);
    const currentHoveredTask = useMultiSelectStore(state => state.currentlyHoveredTask);
    const setCurrentHoveredTask = useMultiSelectStore(state => state.setCurrentHoveredTask);
    const currentTasksHovered = useMultiSelectStore(selectCurrentTasksHovered);
    const lastSelectedTask = useMultiSelectStore(state => state.lastSelectedTask);
    const enableMultiselect = useMultiSelectStore(state => state.enableMultiSelect);
    const setCurrentTasksHovered = useMultiSelectStore(state => state.setCurrentTasksHovered);
    const shiftPressed = useRef(false);    
    const taskIndexMappingRef = useRef({})

    const taskListStyling = {
        width: '100%',
        bgcolor: 'background.paper',
        overflow: 'auto',
        position: 'relative',
        maxHeight: "60vh",
        '& ul': { padding: 0 }
    };
    //#endregion

    //#region Effects
    useEffect(() => {
        if (newTask && newTask.id) {
            if ((taskListTypeFilter && taskListTypeFilter.length == 0) || (taskListTypeFilter && !taskListTypeFilter.includes(newTask.taskTypeId))) {
                setPaginationNumber(1);
                setTaskListTypeFilter([newTask.taskTypeId]);
            }
        }
    },[newTask])

    useEffect(() => {
        getTaskPagination();
    }, [taskListTypeFilter, paginationNumber, pageCount, taskListStatusFilter, searchText, officeListFilter]);

    useEffect(()=>{
        if(taskPagination && taskPagination.tasks && taskPagination.tasks.length > 0){
            setTaskList(taskPagination.tasks);
        }
    }, [taskPagination])

    useEffect(() => {
        let taskDatesRes = new Array(
            ...new Set(
                taskList
                    .sort((a, b) => b.created.localeCompare(a.created))
                    .map(t => new Date(t.created).toLocaleDateString("en-GB"))
            )
        )

        setTaskDates(taskDatesRes);
    }, [taskList]);

    useEffect(() => {
        if(!currentHoveredTask || !lastSelectedTask || !enableMultiselect){
          return;
        }

        if(!shiftPressed.current){
            setCurrentTasksHovered({});
            return;
        }

        let startIdx = Math.min(currentHoveredTask.index, lastSelectedTask.index);
        let endIdx = Math.max(currentHoveredTask.index, lastSelectedTask.index);

        let currentTasksHoveredTemp = {...currentTasksHovered};

        console.log("Assign hover")           
        
        Object.entries(currentTasksHovered).forEach(([taskId, index]) => {
            if(index < startIdx || index > endIdx){
                delete currentTasksHoveredTemp[taskId];
            }
        })

        Array.from({length: endIdx - startIdx + 1}, (_, i) => i + startIdx).forEach(index => {
            let taskId = taskIndexMappingRef.current[index];

            if(shiftPressed.current){
                currentTasksHoveredTemp[taskId] = index;
            }
            else{
                delete currentTasksHoveredTemp[taskId];
            }
        })

        setCurrentTasksHovered(currentTasksHoveredTemp);
        
    }, [shiftPressed, currentHoveredTask])

    useEffect(() => {
        console.log("taskindexmapping", taskIndexMappingRef.current);
    }, [taskIndexMappingRef.current])
    //#endregion
    
    //#region Functions
    /**
     * @param {number} pageCountValue
     */
    function handlePageCount(pageCountValue) {
        setPaginationNumber(1);
        setPageCount(pageCountValue);
    }
    
    function getTaskPagination(){
        setIsTasksLoading(true);
        getTasks(paginationNumber, pageCount, taskListTypeFilter, taskListStatusFilter, officeListFilter, searchText).then((res) => {
            setTaskPagination(res);
        })
        .finally(()=>{
            setIsTasksLoading(false);
            setSelectedTasks({});
            setEnableMultiSelect(false);
            shiftPressed.current = false;
            setLastSelectedTask({index: null});
            setCurrentHoveredTask({index: null});
        });
    }
    
    /**
     * @param {any} _event
     * @param {React.SetStateAction<number>} pageNumber
     */
    function handlePaginationChange(_event, pageNumber) {
        setPaginationNumber(pageNumber);
    }

    function handleSelectAll(){
        setLastSelectedTask({index: null});
        if(taskList.length !== Object.keys(selectedTasks).length){
            setSelectedTasks(taskList.reduce((acc, task) => {
                acc[task.id] = true;
                return acc;
            }, {}));
        }
        else{
            setSelectedTasks({});
        }
    }

    function SelectAllCheckbox(){
        const enableMultiselect = useMultiSelectStore(state => state.enableMultiSelect);

        if(enableMultiselect)
            return(
                    <>
                        <FormControl style={{
                            backgroundColor: "white",
                            paddingLeft: "1.7rem",
                            paddingTop: "0.5rem",
                            borderRadius: "10px 10px 0 0",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            maxWidth: "13rem"
                        }}>
                            <FormControlLabel label="Select all" control={
                                    <Checkbox 
                                        checked={taskList.length == Object.keys(selectedTasks).length} 
                                        indeterminate={taskList.length !== Object.keys(selectedTasks).length && Object.keys(selectedTasks).length > 0}
                                        onClick={handleSelectAll}
                                    />} />
                            <IconButton 
                                    title={"Cancel dismissing tasks"} 
                                    onClick={handleCancelDismiss}
                                    sx={{ cursor: "pointer", width: "24px", margin: 0, padding: 0, marginRight: "0.5rem", marginBottom: "0.2rem", ":hover": { color: Colors.Red, backgroundColor: "transparent" } }}
                            >
                                <Cancel />
                            </IconButton>
                        </FormControl>
                    </>
            )
            else{
                return <div style={{height: 50}}></div>;
            }
    }

    function handleCancelDismiss(){
        setSelectedTasks({});
        setEnableMultiSelect(false);
        if(!filterToggle){
            setLastSelectedTask({index: null});
        }
    }

    /**
     * @param {{ shiftKey: any; }} event
     */
    function handleListKeyDown(event){
        if(event.shiftKey && !shiftPressed.current){
            shiftPressed.current = true;
            setCurrentHoveredTask({...currentHoveredTask})
        }
    }

    function handleListKeyUp(event){
        if(!event.shiftKey && shiftPressed.current){
            shiftPressed.current = false;
            setCurrentHoveredTask({...currentHoveredTask})
        }
    }

    /**
     * @returns {Generator<number>}
     */
    function* getIndex(){
        let index = 1;
        while(true){
            yield index++;
        }
    }

    const indexGenerator = getIndex();
    //#endregion

    return (<>
        {
            isTasksLoading &&
            [1, 2, 3, 4, 5].map(itr => <Skeleton style={{ height: 65 }} key={itr} />)
        }
        <TaskFilters />
        {
            !isTasksLoading && taskList && taskList.length > 0 ?
                <>
                    <ClickAwayListener onClickAway={handleCancelDismiss}>
                        <div>
                            <SelectAllCheckbox />
                            <List onKeyDown={handleListKeyDown} onKeyUp={handleListKeyUp} sx={taskListStyling} dense disablePadding subheader={<li />}>
                                { 
                                    taskDates.map(taskDate =>
                                        taskList.filter(listItem => new Date(listItem.created).toLocaleDateString("en-GB") === taskDate && listItem.pinned).length > 0 &&
                                        <li key={`section-${taskDate}`} >
                                            <ul>
                                                <ListSubheader style={{ top: -1 }}>{taskDate}</ListSubheader>
                                                {
                                                    taskList
                                                    .filter(listItem => new Date(listItem.created).toLocaleDateString("en-GB") === taskDate && listItem.pinned)
                                                    .map(task =>
                                                        <AhrNotification user={user} key={`item=${taskDate}-${task.id}`} task={task} index={indexGenerator.next().value} currentTasksHovered={currentTasksHovered} taskIndexMappingRef={taskIndexMappingRef} shiftPressed={shiftPressed}></AhrNotification>
                                                    )
                                                }
                                            </ul>
                                        </li>
                                    )
                                }
                                {
                                    taskDates.map(taskDate =>
                                        taskList.filter(listItem => new Date(listItem.created).toLocaleDateString("en-GB") === taskDate && !listItem.pinned).length > 0 &&
                                        <li key={`section-${taskDate}`} data-key={`section-${taskDate}`}>
                                            <ul>
                                                <ListSubheader style={{ top: -1 }}>{taskDate}</ListSubheader>{
                                                    taskList.filter(listItem => new Date(listItem.created).toLocaleDateString("en-GB") === taskDate && !listItem.pinned)
                                                    .map(task =>
                                                        <AhrNotification user={user} key={`item=${taskDate}-${task.id}`} task={task} index={indexGenerator.next().value} currentTasksHovered={currentTasksHovered} taskIndexMappingRef={taskIndexMappingRef} shiftPressed={shiftPressed}></AhrNotification>
                                                    )}
                                            </ul>
                                        </li>
                                    )
                                }
                            </List>
                        </div>
                    </ClickAwayListener>
                    <div style={{
                        display: "flex",
                        justifyContent: "end"
                    }}>
                        {taskPagination.pageCount > 1 &&
                            <Pagination count={taskPagination.pageCount} boundaryCount={1} page={paginationNumber} onChange={handlePaginationChange} color="secondary" style={{ display: "flex", flex: 1, justifyContent: "center", marginTop: "1rem" }} />
                        }
                        <Stack direction="row" spacing={4} style={{ flex: 0.1, marginTop: "1rem" }}>
                            <ToggleButtonGroup
                                value={pageCount}
                                exclusive
                                onChange={(_, pcVal) => handlePageCount(pcVal)}
                            >
                                <ToggleButton value={10} style={{ borderRadius: 0}}>
                                    10
                                </ToggleButton>
                                <ToggleButton value={25} style={{ borderRadius: 0}}>
                                    25
                                </ToggleButton>
                                <ToggleButton value={50} style={{ borderRadius: 0}}>
                                    50
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Stack>
                    </div>
                </>
                : !isTasksLoading && taskList && taskList.length === 0 ?
                    <Card>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                No tasks
                            </Typography>
                        </CardContent>
                    </Card>
                    : null
        }
    </>)
}